html {
  &:root {
    --default-white: #ffffff;
    --default-banner-bg: #151515;
    --default-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.10);
  }
  
  &[data-style='white-yellow'] {
    &:root {
      --bg: #ffffff;
      --text: #1C1C1C;
      --button: #FE9900;
      --button-hover: #FEAD33;
      --btn-border-radius: 26px;
      --secondary: #FFE6C1;
      --accent: #FE5200;
      --accent-hover: #0031FE;
      --stroke: #C4C0BA;
    }
  }
  
  &[data-style='white-pink'] {
    &:root {
      --bg: #F7F7F7;
      --text: #202020;
      --button: #CD0AA6;
      --button-hover: #E50CBB;
      --btn-border-radius: 26px;
      --secondary: #F2E4EF;
      --accent: #9D0ACD;
      --accent-hover: #BA0CF2;
      --stroke: #828282;
    }
  }
  
  &[data-style='white-green'] {
    &:root {
      --bg: #F5F5F5;
      --text: #1E2621;
      --button: #85CC16;
      --button-hover: #96E519;
      --btn-border-radius: 26px;
      --secondary: #F5F3C7;
      --accent: #08B904;
      --accent-hover: #09D105;
      --stroke: #B9B9B9;
    }
  }
  
  &[data-style='dark-blue'] {
    &:root {
      --bg: #1C1E24;
      --text: #EBEDF5;
      --button: #56C5FA;
      --button-hover: #70D1FF;
      --btn-border-radius: 10px;
      --secondary: #2A2F3D;
      --accent: #13E4FB;
      --accent-hover: #4FEEFF;
      --stroke: #2668FC;
    }
  }
  
  &[data-style='dark-violet'] {
    &:root {
      --bg: #17141A;
      --text: #FAF7FC;
      --button: #C091FF;
      --button-hover: #CFABFF;
      --btn-border-radius: 20px;
      --secondary: #3A2B4D;
      --accent: #F248FD;
      --accent-hover: #F66BFF;
      --stroke: #665873;
    }
  }
  
  &[data-style='default'] {
    &:root {
      --bg: #ffffff;
      --text: #1C1C1C;
      --button: #DB0E2B;
      --button-hover: #FF1032;
      --btn-border-radius: 4px;
      --secondary: #FAF3F3;
      --accent: #F50000;
      --accent-hover: #9E0000;
      --stroke: #E0E0E0;
    }
  }
}
