@import "color-schemes";

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--text);
}

.wrapper {
  width: 100%;
  height: 100%;
  background: var(--bg);
  position: relative;
}

header {
  width: 100%;
  height: 90px;
  background: var(--text);
  position: relative;
  display: flex;
  justify-content: center;
  
  @media screen and (max-width: 800px) {
    height: initial;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }
  
  .container {
    max-width: 1170px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .logo {
      padding: 0;
      margin: 0;
      display: block;
      
      @media screen and (max-width: 800px) {
        margin-bottom: 10px;
      }
      
      img {
        max-height: 80px;
      }
      
      a,
      span {
        color: var(--button);
        font-size: 20px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 700;
        font-style: italic;
      }
    }
  }
  
  .top-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    @media screen and (max-width: 800px) {
      justify-content: center;
      align-items: stretch;
      
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .btns-block {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      
      @media screen and (max-width: 800px) {
        flex-wrap: initial;
      }
      
      @media screen and (max-width: 560px) {
        justify-content: center;
      }
      
      a {
        margin: 0 10px;
        
        @media screen and (max-width: 800px) {
          width: 45%;
          padding: 14px 35px;
          margin: 0 4px;
          flex: 1;
          
        }
        
        @media screen and (max-width: 560px) {
          width: initial;
          /*max-width: 100px;*/
        }
        
        img {
          width: 20px;
          height: initial;
          display: block;
          position: absolute;
          right: 24px;
          
          @media screen and (max-width: 800px) {
            right: 10px;
          }
          @media screen and (max-width: 560px) {
            right: 8px;
          }
        }
      }
      
      .transperent {
        position: relative;
        margin: 2px 0;
        display: flex;
        color: var(--bg);
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: transparent;
        font-weight: 600;
        font-size: 16px;
        text-decoration: none;
        text-align: center;
        border: 1px solid var(--button);
        
        &:hover {
          background: var(--button-hover);
          box-shadow: var(--default-shadow);
        }
      }
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  
  h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
  }
  
  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
  }
  
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
  }
  
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-decoration: underline;
    color: var(--accent);
    
    &:hover {
      color: var(--accent-hover);
      text-decoration: none;
    }
  }
  
  .primary-menu {
    padding: 20px 40px;
    background: var(--secondary);
    box-shadow: var(--default-shadow);
    border-radius: 8px;
    max-width: 570px;
    width: 100%;
    
    a,
    span {
      color: var(--text);
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      text-decoration: none;
      position: relative;
      
      &:hover {
        text-decoration: underline;
        color: var(--accent-hover);
      }
      
      &:visited {
        color: var(--accent-hover);
      }
      
      &::before {
        content: '♠';
        font-size: 28px;
        display: block;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        position: absolute;
        left: -20px;
        top: 0;
        background: var(--text);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
    
    ul {
      list-style-type: none;
      padding-inline-start: 0;
      
      li {
        display: flex;
        flex-direction: column;
        margin: 5px 0;
        
        a {
          display: flex;
        }
        
        ul {
          margin-left: 20px;
        }
      }
    }
  }
}

.menu {
  display: flex;
  width: 100%;
  max-width: 1170px;
  margin-top: 35px;
  
  .current-menu-item {
    span {
      text-decoration: none;
      color: var(--accent);
      cursor: default;
      
      &:hover {
        text-decoration: none;
      }
    }
  }
}


ul.toc {
  padding: 20px 40px;
  background: var(--secondary);
  box-shadow: var(--default-shadow);
  border-radius: 8px;
  max-width: 570px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 35px;
  
  @media screen and (max-width: 680px) {
    width: calc(100% - 80px);
  }
  
  &.left {
    float: left;
    margin: 0 35px 35px 0;
    
    @media screen and (max-width: 768px) {
      float: none;
      margin-bottom: 35px;
    }
  }
  
  &.right {
    float: right;
    margin: 0 0 35px 35px;
    
    @media screen and (max-width: 768px) {
      float: none;
      margin-bottom: 35px;
    }
  }
  
  &.center {
    float: none;
    margin: 0 auto 35px;
    
    @media screen and (max-width: 768px) {
      float: none;
      margin-bottom: 35px;
    }
  }
  
  li {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    
    ul {
      margin-left: 20px;
      padding: 0;
    }
    
    a,
    span {
      color: var(--text);
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      text-decoration: none;
      position: relative;
      
      &:hover {
        text-decoration: underline;
        color: var(--accent-hover);
      }
      
      &:visited {
        color: var(--accent-hover);
      }
      
      &::before {
        content: '♠';
        font-size: 28px;
        display: block;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        position: absolute;
        left: -20px;
        top: 0;
        background: var(--text);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
  }
}

.banner {
  width: 100%;
  background: var(--default-banner-bg);
  box-sizing: border-box;
  overflow: hidden;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  .container {
    width: 100%;
    height: 100%;
    max-width: 1170px;
    display: block;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .banner-content {
    left: 0;
    padding-left: 0;
    
    @media screen and (max-width: 1170px) {
      padding-left: 15px;
    }
    
    @media screen and (max-width: 680px) {
      top: 20%;
    }
    
    a {
      width: fit-content;
    }
  }
  
  img {
    max-width: 1170px;
    @media screen and (max-width: 560px) {
      max-width: initial;
      height: 100%;
    }
  }
  
  a {
    display: flex;
    justify-content: center;
  }
}

.title {
  max-width: 1170px;
  width: 100%;
}

.banner-content {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0 0 0 40px;
  
  @media screen and (max-width: 680px) {
    top: 0;
    transform: initial;
    padding: 0;
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
  }
}

.sub-banner {
  max-width: 1170px;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 275px;
  
  @media screen and (max-width: 680px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__title {
    color: var(--default-white);
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  
  &__subtitle {
    color: var(--default-white);
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    display: block;
    width: 100%;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  
  .btn {
    display: inline-block;
    color: var(--bg);
  }
  
  img {
    max-width: 1170px;
  }
  
  .banner-desktop {
    box-sizing: border-box;
    
    img {
      max-width: 100%;
    }
  }
}

.btn {
  position: relative;
  margin: 2px 0;
  display: flex;
  color: var(--bg);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--button);
  border-radius: var(--btn-border-radius);
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  padding: 14px 28px;
  box-shadow: var(--default-shadow);
  
  &.shortcode-btn {
    max-width: fit-content;
    min-width: 120px;
    display: block;
    margin-top: 25px;
    
    &.left {
      float: left;
      margin: 25px 25px 25px 0;
      
      @media screen and (max-width: 768px) {
        float: none;
        margin: 25px auto;
      }
    }
    
    &.right {
      float: right;
      margin: 25px 0 25px 25px;
      
      @media screen and (max-width: 768px) {
        float: none;
        margin: 25px auto;
      }
    }
    
    &.center {
      float: none;
      margin: 25px auto 25px;
      
      @media screen and (max-width: 768px) {
        float: none;
        margin: 25px auto;
      }
    }
  }
  
  &.has-icon {
    padding: 14px 55px 14px 28px;
  }
  
  &:hover {
    background: var(--button-hover);
    box-shadow: none;
    color: var(--bg);
  }
}

.description {
  width: 100%;
  max-width: 1170px;
  margin-top: 30px;
  
  img {
    max-width: 100%;
  }
  
  table {
    background: var(--bg);
    border-radius: 8px;
    border-spacing: 0;
    margin: 20px 0;
    overflow: hidden;
    
    tr {
      td, th {
        &:first-child {
          padding-left: 25px;
        }
        
        &:last-child {
          padding-right: 25px;
        }
      }
    }
    
    th {
      text-align: left;
      padding: 13px;
      border-bottom: 1px solid var(--stroke);
    }
    
    tr {
      td {
        padding: 13px;
        border-bottom: 1px solid var(--stroke);
      }
      
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
    
    &.default {
      th {
        color: var(--text);
        background: var(--bg);
      }
      
      tr {
        td {
          border-bottom: 1px solid var(--stroke);
        }
        
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
    
    &.colored {
      background: var(--secondary);
      box-shadow: var(--default-shadow);
      
      th {
        color: var(--bg);
        background: var(--accent);
        border-bottom: 1px solid var(--bg);
      }
      
      tr {
        td {
          border-bottom: 1px solid var(--stroke);
        }
        
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
  
  blockquote {
    background: var(--secondary);
    box-shadow: var(--default-shadow);
    border-radius: 8px;
    padding: 20px 40px;
  }
}

footer {
  display: flex;
  width: 100%;
  height: 78px;
  justify-content: center;
  align-items: center;
  background: var(--secondary);
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--text);
  margin-top: 60px;
  
  &.disclaimer-false {
    padding-bottom: 0 !important;
  }
}

.to-top {
  position: fixed;
  bottom: 17vh;
  right: calc((100% / 2) - 650px);
  width: 50px;
  height: 50px;
  background: var(--button);
  box-shadow: var(--default-shadow);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: all .3s;
  visibility: hidden;
  
  &:hover {
    background: var(--button-hover);
    box-shadow: none;
  }
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 27px;
    background-color: var(--bg);
  }
  
  &::before {
    top: 20%;
    transform: rotate(40deg);
    left: 15px;
  }
  
  &::after {
    top: 20%;
    transform: rotate(-40deg);
    right: 15px;
  }
  
  &.show{
    visibility: visible;
    opacity: 1;
    transition: all .3s;
  }
}


.to-top.show {
  display: flex;
}

.page-not-found {
  display: flex;
  width: 100%;
  max-width: 1070px;
  margin-top: 50px;
  align-items: center;
  
  &__content {
    color: var(--text);
    
    &__title {
      font-weight: 500;
      font-size: 120px;
      line-height: 141px;
    }
    
    &__message {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }
  }
  
  &__drum {
    display: flex;
  }
}

@media screen and (max-width: 1170px) {
  header .container {
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .title,
  .sub-banner,
  .menu,
  .description {
    margin: 15px;
    width: calc(100% - 30px);
  }
  
  .page-not-found {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
    
    &__drum {
      display: flex;
      justify-content: center;
      
      img {
        width: 90%;
      }
    }
  }
  
  .to-top {
    right: 15px;
    bottom: 15px;
  }
  
}

@media screen and (max-width: 540px) {
  header {
    .container {
      .logo {
        a,
        span {
          font-size: 14px;
        }
      }
    }
  }
  
  .logo-image {
    max-width: 90%;
    max-height: 80px;
  }
  
  .page-not-found {
    
    &__content {
      color: var(--text);
      display: flex;
      flex-direction: column;
      align-items: center;
      
      &__title {
        font-size: 64px;
        line-height: 75px;
      }
      
      &__message {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

.cover-table {
  margin-bottom: 25px;
  
  table {
    margin-bottom: 0;
  }
  
  @media screen and (max-width: 600px) {
    overflow-x: scroll;
  }
}

h2.faq {
  margin-top: 50px;
  padding-bottom: 0px;
}

.faq-container {
  order: 4;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  position: relative;
  
  .faq-content {
    text-align: justify;
    height: 0;
    overflow: hidden;
    pointer-events: none;
  }
  
  h3 {
    margin: 6px 0;
    pointer-events: none;
    color: var(--text);
  }
  
  .faq-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 50px 10px 35px;
    height: initial;
    cursor: pointer;
    margin-bottom: 10px;
    line-height: 1.4;
    border-radius: 5px;
    background: var(--secondary);
    position: relative;
    box-shadow: var(--default-shadow);
    
    &:hover {
      background: var(--accent);
      
      * {
        color: var(--bg);
      }
      
      .chevron {
        color: var(--bg);
      }
    }
    
    .chevron {
      position: absolute;
      right: 20px;
      top: 27px;
      transform: translate(-50%, -50%);
      line-height: 1;
      display: block;
      color: var(--accent);
      
      &:before {
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        content: '';
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        vertical-align: top;
        width: 0.45em;
        top: 0;
        transform: rotate(135deg);
      }
    }
    
    &:after {
      content: '';
    }
    
    h3 {
      display: block;
      width: 100%;
    }
    
    &.show {
      background: var(--accent);
      color: var(--bg);
      
      h3 {
        color: var(--bg);
      }
      
      .chevron {
        &:before {
          transition: all .3s;
          transform: rotate(315deg);
          color: var(--bg);
        }
      }
      
      .faq-content {
        height: auto;
        color: var(--bg);
        padding: 20px 0;
      }
    }
  }
}


